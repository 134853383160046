<template>
  <content-wrapper>
    <template v-slot:top=""><base-go-back /></template>
    <base-form @submit.prevent="handleSubmit">
      <base-form-title>Registrar nuevo usuario</base-form-title>

      <base-divider text="Información Personal" />
      <base-input
        id="name"
        label="Nombre"
        placeholder="Nombre"
        :errors="nameErrors"
        @blur="$v.model.name.$touch()"
        v-model="model.name"
      />
      <base-input
        id="lastname"
        label="Apellido paterno"
        placeholder="Apellido Paterno"
        :errors="lastnameErrors"
        @blur="$v.model.lastname.$touch()"
        v-model="model.lastname"
      />
      <base-input
        id="surname"
        label="Apellido Materno"
        placeholder="Apellido Materno"
        :errors="surnameErrors"
        @blur="$v.model.surname.$touch()"
        v-model="model.surname"
      />
      <base-input
        id="email"
        label="Email"
        placeholder="Email"
        :errors="emailErrors"
        @blur="blurEmail"
        v-model="model.email"
      />
      <base-input-select
        id="company"
        label="Empresa"
        :errors="companyIdErrors"
        @blur="$v.model.companyId.$touch()"
        v-model="model.companyId"
        :options="companies"
      />

      <base-divider text="Información del usuario" />
      <base-input
        id="username"
        label="Nombre de usuario"
        placeholder="Nombre de usuario"
        :errors="usernameErrors"
        @blur="blurUsername"
        v-model.lazy="model.username"
      />
      <base-input-select
        id="role"
        label="Rol de usuario"
        :errors="roleErrors"
        @blur="$v.model.role.$touch()"
        v-model="model.role"
        :options="userRoles"
      />
      <base-input
        id="password"
        label="Contraseña"
        placeholder="Contraseña"
        :errors="passwordErrors"
        @blur="$v.model.password.$touch()"
        v-model="model.password"
      />
      <base-form-button-group>
        <base-button
          appendIcon="times-circle"
          bgColor="#FB3640"
          @click="$router.go(-1)"
        >
          Cancelar
        </base-button>
        <base-button
          bgColor="#FF8600"
          appendIcon="save"
          :submit="true"
          :disabled="isInvalidForm || submitLoading"
          :loading="submitLoading"
        >
          Guardar
        </base-button>
      </base-form-button-group>
    </base-form>
  </content-wrapper>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "create-user",
  data: () => ({
    model: {
      name: "",
      lastname: "",
      surname: "",
      email: "",
      companyId: "",
      username: "",
      role: "",
      password: "",
    },
    companies: [],
    isInvalidEmail: false,
    isInvalidUsername: false,
    submitLoading: false,
  }),
  created() {
    this.$store.dispatch("Company/fetchAll").then(({ status, data }) => {
      if (status === 200) {
        this.companies = data.map((el) => {
          return { value: el.id, text: el.name };
        });
      }
    });
  },
  methods: {
    blurUsername() {
      this.validateUsername();
      this.$v.model.username.$touch();
    },
    blurEmail() {
      this.validateEmail();
      this.$v.model.email.$touch();
    },
    validateUsername() {
      this.$store
        .dispatch("Recruiter/usernameExists", {
          data: { param: this.model.username },
        })
        .then((res) => {
          console.log("res username", res);
          this.isInvalidUsername = res.data.exists;
        });
    },
    validateEmail() {
      this.$store
        .dispatch("Recruiter/emailExists", {
          data: { param: this.model.email },
        })
        .then((res) => {
          console.log("res email", res);
          this.isInvalidEmail = res.data.exists;
        });
    },
    handleSubmit() {
      this.submitLoading = true;
      this.$store
        .dispatch("Recruiter/add", { data: this.formatedModel })
        .then(({ status }) => {
          if (status === 200) {
            this.$swal.fire({
              icon: "success",
              title: "Registrado!",
              text: "El usuario ha sido registrado con existo.",
              showClass: {
                popup: "animate__animated animate__zoomIn animate__faster",
              },
              hideClass: {
                popup: "animate__animated animate__zoomOut animate__faster",
              },
            });
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters("Recruiter", ["userRoles"]),
    formatedModel() {
      let model = {};
      for (const el in this.model) {
        if (el === "role") {
          model[el] = [this.model[el]];
        } else {
          model[el] = this.model[el];
        }
      }
      return model;
    },
    isInvalidForm() {
      let invalid =
        this.$v.model.$invalid || this.isInvalidUsername || this.isInvalidEmail;
      return invalid;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.model.name.$dirty) return "";
      !this.$v.model.name.required && errors.push("Campo requerido");
      return errors[0];
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.model.lastname.$dirty) return "";
      !this.$v.model.lastname.required && errors.push("Campo requerido");
      return errors[0];
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.model.surname.$dirty) return "";
      !this.$v.model.surname.required && errors.push("Campo requerido");
      return errors[0];
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.model.email.$dirty) return "";
      !this.$v.model.email.required && errors.push("Campo requerido");
      !this.$v.model.email.email && errors.push("Ingrese un email valido");
      this.isInvalidEmail && errors.push("Email ya en uso");
      return errors[0];
    },
    companyIdErrors() {
      const errors = [];
      if (!this.$v.model.companyId.$dirty) return "";
      !this.$v.model.companyId.required && errors.push("Campo requerido");
      return errors[0];
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.model.username.$dirty) return "";
      !this.$v.model.username.required && errors.push("Campo requerido");
      this.isInvalidUsername && errors.push("Nombre de usuario ya en uso");
      return errors[0];
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.model.role.$dirty) return "";
      !this.$v.model.role.required && errors.push("Campo requerido");
      return errors[0];
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.model.password.$dirty) return "";
      !this.$v.model.password.required && errors.push("Campo requerido");
      return errors[0];
    },
  },
  mixins: [validationMixin],
  validations: {
    model: {
      name: { required },
      lastname: { required },
      surname: { required },
      email: { required, email },
      companyId: { required },
      username: { required },
      role: { required },
      password: { required },
    },
  },
};
</script>
